import React from 'react'
import { Code, List } from 'react-content-loader'

const Loading = () => (
  <div className="loading">
    <div className="sidebar">
      <List />
      <List />
      <List />
      <List />
      <List />
      <List />
      <List />
      <List />
      <List />
      <List />
    </div>
    <div className="content">
      <Code />
      <Code />
      <Code />
      <Code />
      <Code />
      <Code />
      <Code />
      <Code />
    </div>
  </div>
)

export default Loading