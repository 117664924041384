import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { API } from "@stoplight/elements";
import '@stoplight/elements/styles.min.css';
import { useWindowWidth } from '@react-hook/window-size'

import './App.css';
import Feedback from './Feedback.js';
import Loading from './Loading.js';

const schemaUrlsAllowlist = [
  "https://api.cloudflare.com/",
  "https://raw.githubusercontent.com/cloudflare/",
]

// Uncomment and add src/schema.json to use local schema
// import schema from './schema.json'

function App() {
  const [loading, setLoading] = useState(true)

  const width = useWindowWidth()
  const layout = useMemo(() => width > 780 ? 'sidebar' : 'stacked', [width])

  const rawUrl = "https://raw.githubusercontent.com/cloudflare/api-schemas/main/openapi.json"
  const url = "/schema"

  const [schema, setSchema] = useState({})

  // If using local schema file, uncomment the following lines of code
  // and comment the useEffect block below
  // setSchema(schema)
  // setLoading(false)

  useEffect(() => {
    const schemaUrlParam = new URL(window.location).searchParams.get("schema_url")
    const urlToFetch = schemaUrlParam || url
    fetch(urlToFetch)
      .then(resp => resp.text()
        .then(schema => {
          if (urlToFetch === "/schema") {
            setSchema(schema)
            setLoading(false)
          } else if (schemaUrlsAllowlist.find(url => urlToFetch.startsWith(url))) {
            setSchema(schema)
            setLoading(false)
          } else {
            console.log("Schema URL not supported. This application will only load schema URLs from known Cloudflare URLs.")
          }
        })
      ).catch(() => {
        // If schema is not available, fetch from raw url
        fetch(rawUrl)
          .then(resp => resp.text()
            .then(schema => {
              setSchema(schema)
              setLoading(false)
            })
          )
      })
  }, [])

  useLayoutEffect(() => {
    if (document) {
      const container = document.querySelector(
        layout === 'sidebar' ? ".sl-elements-api" : ".sl-elements"
      )

      if (container) {
        const content = layout === 'sidebar' ? container.lastChild.firstChild : container.firstChild.firstChild

        const existingLink = document.querySelector("#docsearch")
        if (existingLink) {
          console.log('removing existing')
          existingLink.remove()
        }

        const div = document.createElement("div")
        div.style.width = '100%'
        div.style.marginBottom = '20px'
        if (layout === 'sidebar') div.style.marginTop = '-40px'
        div.id = "docsearch"

        // insert at beginning of content
        content.insertBefore(div, content.firstChild)

        setTimeout(() => {
          // find expand query param
          const expand = new URL(window.location).searchParams.get("_expand")
          if (expand === "true") {
            document
              .querySelector(".sl-elements-api")
              .firstChild
              .querySelectorAll("div.sl-cursor-pointer:not(#sl-toc-\\/)")
              .forEach(el => el.click())
          }
        }, 100)

        window.docsearch({
          container: '#docsearch',
          appId: 'BC1TY5QF4Y',
          indexName: 'developers-cloudflare',
          apiKey: '514d1ab896ba1058ade0c878da4c8321',
          maxResultsPerGroup: 20,
          insights: true
        });
      }
    }
  }, [schema, layout])

  return (
    <div style={{ height: '100vh' }}>
      {loading ?
        <Loading /> :
        <>
          <Feedback />
          <API
            apiDescriptionDocument={schema}
            basePath={"/api"}
            hideSchemas
            layout={layout}
            logo='/logo.svg'
          />
        </>
      }
    </div>
  );
}

export default App;
